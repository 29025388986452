export const shadedButton = (color: string) => `bg-gradient-radial-at-b from-${color}-500 to-${color}-300`

export const defaultColors = {
  backgroundGradient: {
    from: 'from-blue-50',
    via: 'via-indigo-50',
    to: 'to-purple-50',
  },
  title: 'text-indigo-900',
  cardTitle: 'text-indigo-800',
  cardTitleHover: 'group-hover:text-purple-700',
  cardSubtitle: 'text-indigo-600',
  cardSubtitleHover: 'group-hover:text-purple-600',
  cardDescription: 'text-gray-700',
  cardDescriptionHover: 'group-hover:text-gray-900',
};

export const defaultBackgroundShapeColors = {
  shape1: 'bg-blue-300',
  shape2: 'bg-purple-300',
  shape3: 'bg-pink-300',
};

export const lightBackgroundShapeColors = {
    shape1: 'bg-blue-100',
    shape2: 'bg-purple-100',
    shape3: 'bg-pink-100',
  };

export const defaultBackgroundShapeColors2 = {
    shape1: 'bg-red-300',
    shape2: 'bg-orange-300',
    shape3: 'bg-pink-300',
  };

  export const defaultColors2 = {
    backgroundGradient: {
      from: 'from-red-50',
      via: 'via-orange-50',
      to: 'to-pink-50',
    },
    title: 'text-indigo-900',
    cardTitle: 'text-indigo-800',
    cardTitleHover: 'group-hover:text-purple-700',
    cardSubtitle: 'text-indigo-600',
    cardSubtitleHover: 'group-hover:text-purple-600',
    cardDescription: 'text-gray-700',
    cardDescriptionHover: 'group-hover:text-gray-900',
  };